<template>
  <div v-if="Carga" class="mb-2 card p-2" :class="CardClass">
    <Main-Cargando :cargando="cargando" :MsgError="MsgError">
      <div v-if="LaNotificacion.tipoNotificacion == 'NoDefinida'">
        <h5 class="pull-right p-0">{{ Fecha }}</h5>
        <router-link :to="{}" @click.native="OnMostrarNoDefinida">
          <img
            v-if="Carga.IconoUrl"
            :src="Carga.IconoUrl"
            :class="Carga.Icono"
            class="float-left mr-2"
            width="48"
            style="height: 48px"
          />
          <i v-else :class="Carga.Icono"></i>

          <h5 class="mb-0 pb-0 text-truncate">
            {{ Carga.Titulo }}
          </h5>
          <h5 class="mb-0 pb-0 text-truncate">
            <small>{{ Carga.SubTitulo }}</small>
          </h5>
          <p class="mb-0 pb-0 text-truncate">
            {{ CrearDescripcion(Carga.Aplicacion,Carga.Descripcion) }}
            <!-- {{ Carga.Descripcion }} -->
          </p>
        </router-link>
      </div>
      <div v-else-if="Carga.Colaborador">
        <router-link :to="{}" @click.native="OnMostrar">
          <img
            :src="Carga.Colaborador | Foto"
            class="rounded-circle float-left mr-2"
            width="48"
            style="height: 48px"
          />
          <h4 class="mb-0 pb-0 pt-1 text-truncate">
            {{ Carga.Colaborador.Etiqueta }}
          </h4>
          <h5 class="mb-0 pb-0 text-truncate">
            <i :class="Icono"></i>{{ Etiqueta }} {{ Carga.Colaborador.LinkID }}
          </h5>
        </router-link>
      </div>
      <div v-else>
        <router-link
          :to="{}"
          class="pull-right p-2"
          data-effect="fadeOut"
          @click.native.stop="OnBorrar()"
        >
          <i class="fa fa-times"></i>
        </router-link>
        <router-link
          class=""
          :to="{}"
          data-effect="fadeOut"
          @click.native="OnMostrar"
        >
          <img
            v-if="Carga.Respuesta && Carga.Respuesta.Colaborador"
            :src="Carga.Respuesta.Colaborador | Foto"
            class="rounded-circle float-left mr-2"
            width="48"
            style="height: 48px"
          />
          <h4
            v-if="Carga.Respuesta && Carga.Respuesta.Colaborador"
            class="mb-0 pb-0 pt-1 text-truncate"
          >
            {{ Carga.Respuesta.Colaborador.Etiqueta }}
          </h4>
          <h4 class="mb-0 pb-0 text-truncate">
            <i :class="Icono"></i> {{ Etiqueta }}
          </h4>
          <h5 class="mb-0 pb-0 text-truncate">{{ Datos }}</h5>
        </router-link>
      </div>
    </Main-Cargando>

    <NotificacionModal
      v-if="Modal"
      :Mostrar.sync="Modal"
      :MsgError="MsgError"
      v-model="LaNotificacion"
      @autorizar="OnAutorizar"
      :cargando="cargando"
      @Borrar="OnBorrar()"
    ></NotificacionModal>
  </div>
</template>

<script>
import moment from "moment";
import { formatDateToCustomString } from "@/api-services/LiFecha";
//var moment = require('moment');
moment.locale("es-mx");
import NotificacionModal from "./Notificacion.modal.vue";
import Notificaciones from "@/api-services/notificaciones.service";
import Colaboradores from "@/api-services/colaboradores.service";
export default {
  name: "LayOuts-Notificacion",
  props: {
    value: Object,
  },
  components: {
    NotificacionModal,
  },
  data() {
    return {
      LaNotificacion: null,
      Carga: null,
      Modal: false,
      cargando: false,
      Erroneo: "",
      Comentarios: "",
      MsgError: "",
    };
  },
  watch: {
    Modal(Modal) {
      // return;

      if (!Modal) {
        console.debug(`update:Modal= ${Modal}`);
        this.$nextTick(() => {
          this.$emit("actualizar", this.value);
        });
      }
    },
  },
  computed: {
    Fecha() {
      return formatDateToCustomString(this.LaNotificacion.creado);
    },
    CardClass() {
      if (
        this.Carga.Respuesta != undefined &&
        this.Carga.Respuesta.Aceptada != undefined
      ) {
        if (this.Carga.Respuesta.Aceptada) return "bg-success";
        else return "bg-danger";
      }
      return "dropdown-menu-ancho";
    },
    EsperaRespuesta() {
      return Notificaciones.EsperaRespuesta(
        this.LaNotificacion.tipoNotificacion
      );
    },
    TituloDias() {
      if (this.Carga && this.Carga.Dias) {
        if (this.Carga.Dias.length > 1) return "Para los siguientes días:";
        return "Para el siguiente día:";
      }
      return "";
    },
    Icono() {
      return Notificaciones.Icono(this.LaNotificacion.tipoNotificacion);

      //fa fa-times
    },
    Etiqueta() {
      return Notificaciones.Etiqueta(this.LaNotificacion.tipoNotificacion);
    },
    Datos() {
      return Notificaciones.Datos(this.Carga);
    },
    Titulo() {
      return Notificaciones.Titulo(this.LaNotificacion.tipoNotificacion);
    },
  },
  mounted() {
    this.LaNotificacion = Object.assign({}, this.value);
    this.Carga = JSON.parse(this.value.carga);
  },
  filters: {
    Foto(Registro) {
      return Colaboradores.FotoURL(Registro);
    },
  },
  methods: {
    OnMostrar() {
      if (Notificaciones.Redirigir(this, this.LaNotificacion)) {
        //this.$router.push(`/empleado/acceso-nuevo?n=${this.LaNotificacion.id}`);
        return;
      }
      this.Modal = true;
    },
    FDia(Dia) {
      return moment(Dia).format("dddd D/MMMM/YY");
    },

    OnAutorizar(Parametros) {
      if (Parametros == undefined)
        Parametros = { Comentarios: "", Autorizar: false };
      Notificaciones.put(
        this,
        this.LaNotificacion.id,
        {
          respuesta: Parametros.Comentarios,
          aceptada: Parametros.Autorizar,
        },
        (response) => {
          /*  if (response.data.borrado) {
              for (var i = 0; i < Notificaciones.Notificaciones.length; i++) {
                console.debug(Notificaciones.Notificaciones[i].id);
                if (Notificaciones.Notificaciones[i].id == response.data.id) {

                  Notificaciones.Notificaciones.borrado = true;
                  i--;
                }
              }
              }*/
          console.debug("Cerrando");
          this.Modal = false;
        },
        (MsgError, error) => {
          if (error.response == undefined) {
            MsgError = "Intente nuevamente en un par de minutos";
          } else
            switch (error.response.status) {
              case 404:
                MsgError = "No tiene permisos de modificación";
                break;
            }
          return MsgError;
        }
      );
    },
    OnBorrar() {
      Notificaciones.delete(this, this.LaNotificacion.id, (response) => {
        console.debug("Esto obtuve", response);
        console.debug("Se ha borrado la notificacion", this.LaNotificacion.id);
        this.$emit("Actualizar", this);
      });
    },
    OnMostrarNoDefinida() {
      this.OnBorrar();
      console.debug("Mostrando la notificacion no definida");
      //* Guardar en el back que ya se abrio o eliminarla
      if (this.Carga.URL)
        window.open(this.Carga.URL, "_blank");
    },

    CrearDescripcion(Tipo,Descripcion){
      if (Tipo != undefined && Tipo =="eTickets") {
        if (Descripcion.includes("/")) {
          let nuevaDescp =Descripcion.split("/")
          return  nuevaDescp[nuevaDescp.length -1]
        }
      }
      return Descripcion
    },
  },
};
</script>

<style scoped></style>

<style>
@media (min-width: 768px) {
  .dropdown-menu-ancho {
    width: 500px !important;
  }
}

@media (max-width: 767.98px) {
  .dropdown-menu-ancho {
    width: 100% !important;
  }
}
</style>
